<template>
  <div>
    <v-container
      class="v-container-fluid"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <div
            v-if="isResponsive"
            class="bg-welcome-proplat"
          >
            <img
              class="fruits-image"
              src="@/assets/images/fruits-welcome.png"
              alt=""
            >
            <div style="margin-top: 70px;">
              <p class="txt-title text-center mon-bold">{{ texts.banner.welcome }}</p>
              <p
                class="txt-description text-center mon-medium"
                v-html="texts.banner.description"
              />
            </div>
          </div>
          <div
            v-else
            class="bg-welcome-proplat"
          >
            <img
              class="fruits-image hidden-from-tablet"
              src="@/assets/images/fruits-welcome.png"
              alt=""
            >
            <p class="txt-title mon-bold">{{ texts.banner.welcome }}</p>
            <p
              class="txt-description mon-medium"
              v-html="texts.banner.description"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  name: "HomeBanenrLayout",
  data() {
    return {
      //VARIABLES
      isResponsive: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
  },
};
</script>

<style scoped>
.fruits-image {
  position: absolute;
  width: 600px;
  right: -20px;
  top: 0px;
  overflow: hidden;
}

.txt-title {
  color: #ffffff;
  text-align: left;
  letter-spacing: 0px;
  text-shadow: 0px 3px 6px #00000095;
  opacity: 1;
  font-size: 30px;
}

.txt-description {
  color: #ffffff;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  text-shadow: 0px 3px 6px #00000095;
  opacity: 1;
  font-size: 14px;
}

@media (max-width: 960px) {
  .hidden-from-tablet {
    display: none;
  }

  .fruits-image {
    left: 50%;
    transform: translate(-50%, 0%);
    width: 350px;
  }

  .txt-title {
    font-size: 25px;
  }
}

@media (max-width: 600px) {
  .txt-description {
    font-size: 12px;
  }
}
</style>

<style>
.bg-welcome-proplat {
  background: url("../../../assets/images/bg-banner-welcome.png");
  width: 100%;
  opacity: 1;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 25px;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}

@media (max-width: 960px) {
  .bg-welcome-proplat {
    padding: 15px;
  }
}
</style>